/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

/**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
 */
 
@import 'all/custom/variables.scss';

// Import Bootstrap source files (Required file)
@import 'all/bootstrap/bootstrap.scss';

@import 'all/custom/custom.scss'; 

//This is for the icons (Required file)
@import 'all/icons/font-awesome/css/all.css';

@font-face {
    font-family: 'Kentledge';
    src: url('../fonts/Kentledge-Regular.woff2') format('woff2'), url('../fonts/Kentledge-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
	transform: perspective(1px);
}

h2, h6, td, canvas{
	font-weight: 400 !important;
}

input:disabled {
  background-color: #f6f6f6 !important;
  opacity: 1 !important;
}

.left-sidebar{
-webkit-box-shadow: 10px 10px 32px -28px rgba(0,0,0,0.30);
-moz-box-shadow: 10px 10px 32px -28px rgba(0,0,0,0.30);
box-shadow: 10px 10px 32px -28px rgba(0,0,0,0.30);
background: #f6f6f6 !important;
}

#main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul{
background: #f6f6f6 !important;
}

#main-wrapper[data-layout='vertical'][data-sidebartype='full'] .page-wrapper{
margin-left: 200px;
}

input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #d12a5e;
	border: none;
	cursor: pointer;
	transition: background 0.3s ease;
  }

  input[type="range"]::-webkit-slider-thumb:hover {
	background: #9b1942;
  }

  input[type="range"]::-moz-range-thumb {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #d12a5e;
	border: none;
	cursor: pointer;
  }

.sidebar-nav ul .sidebar-item .sidebar-link i, .sidebar-nav ul .sidebar-item .sidebar i{
font-size: 15.5px !important;
}

.stats-display{
display: flex;
justify-content: center;
text-align: center;
padding: 18px 20px;
border-radius: 10px;
background-color: #f6f6f6;
border-width: 1px;
border-style: solid;
border-color: rgba(0,0,0,0.06);
-webkit-box-shadow: 10px 10px 20px -16px rgba(0,0,0,0.12);
-moz-box-shadow: 10px 10px 20px -16px rgba(0,0,0,0.12);
box-shadow: 10px 10px 20px -16px rgba(0,0,0,0.12);
}

.stats-td{
	border: none !important;
	width: 25%;
}

.side-stats{
	min-width: 100% !important;
	padding: 20px 20px 40px 20px !important;
	margin-top: 30px;
}

.graph-display{
	padding: 30px;
	border-radius: 10px;
	background-color: #f6f6f6;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(0,0,0,0.06);
	-webkit-box-shadow: 10px 10px 20px -16px rgba(0,0,0,0.12);
	-moz-box-shadow: 10px 10px 20px -16px rgba(0,0,0,0.12);
	box-shadow: 10px 10px 20px -16px rgba(0,0,0,0.12);
	overflow: hidden;
}

.side-stats-display{
	border-radius: 10px;
	margin-top: 22px;
	overflow: hidden;
	width: 100%;
	padding: 3px;
}

.side-stats-icon{
	width: 52px;
	height: 52px;
	border-radius: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.03);
}

.ticketshopContainer{
	position: relative;
	width: 100%;
	padding: 40px;
	background-color: rgba(0,0,0,0.02);
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.ticketshopContainer h2{
	width: 100% !important;
}

.apikeyContainer{
	position: relative;
	width: 100%;
	padding: 40px;
	background-color: rgba(0,0,0,0.02);
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.ticketshopAdd{
    width: 250px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d12a5e;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    margin-top: 30px;
}

.settingsListBtn{
    width: 170px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d12a5e;
    border-radius: 10px;
	font-size: 12px;
    color: #d12a5e;
    cursor: pointer;
    margin-top: 20px;
}

.settingsListBtn:hover{
    background: #d12a5e;
    color: #fff;
}


.fixedAmountSetup{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(242, 242, 242);
    border-radius: 10px;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(0,0,0,0.08);
    color: #212529;
    cursor: pointer;
    margin-top: 0px;
}

.linkSetup{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d12a5e;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    margin-top: 0px;
}

.ticketshopDel{
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
	cursor: pointer;
}

.addShopBtn{
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eaeaea;
    border-radius: 10px;
    font-size: 12px;
    padding-bottom: 1px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px;
}

.uitdraaiBtn{
	display: flex; 
	justify-content: center; 
	align-items: center; 
	border-radius: 7px; 
	border-width: 1px; 
	border-style: solid; 
	border-color: #d9d9d9; 
	cursor: pointer;
	height: 40px; 
	margin-left: 20px;
	width: 125px; 
	color: #000;
}

.refreshBtn{
	display: flex; 
	justify-content: center; 
	align-items: center; 
	border-radius: 7px; 
	border-width: 1px; 
	border-style: solid; 
	border-color: #d9d9d9; 
	cursor: pointer;
	height: 40px; 
	margin-left: 20px;
	width: 40px; 
	color: #525f7f;
	background-color:rgba(0,0,0,0.03);
}

.dropdown-item:active, .dropdown-item:focus {
	background: none !important;
	color: #000 !important;
	border: 0px !important;
	outline: none !important;
}

.filebtn{
	display: flex; 
	justify-content: center; 
	align-items: center; 
	border-radius: 7px; 
	border-width: 1px; 
	border-style: solid; 
	border-color: #d9d9d9; 
	cursor: pointer;
	height: 35px; 
	margin-left: 12px;
	width: 40px; 
	padding-top: 2px;
	color: #525f7f;
	font-size: 11px;
	background-color:rgba(0,0,0,0.03);
}

.uitdraaiBtn2{
	display: block;
	width: 170px;
	background-color:rgb(209, 42, 94);
	color: #fff;
	text-align: center; 
	font-size: 14px; 
	font-weight: 500;
	border-radius: 7px; 
	height: 34px;
	padding-top: 7px;
}

.uitdraaiBtn2:hover{
	background: #e75a86;
	color: #fff;
}

.uitdraaiBtn:hover{
	background-color:rgba(0,0,0,0.05);
	color: #525f7f;
}

.visitorBlock{
	background-color: rgba(0,0,0,0.04);
	border-radius: 15px;
	width: 100%;
	height: 140px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.visitorIcon{
	margin-bottom: 10px;
	font-size: 26px;
	color: rgba(89, 89, 89, 0.8) !important;
}

.switchBtn{
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
padding-top: 8px;
padding-left: 15px;
padding-right: 15px;
padding-bottom: 6px;
border-radius: 10px;
border-width: 1px;
border-style: solid;
border-color: rgba(0,0,0,0.1);
margin-bottom: 20px;
}

.switchBtn2{
	flex-direction: column;
	justify-content: center;
}

.cancel_btn{
background: #f5f5f5;
color: #212529;
border-width: 1px;
border-color: #dee2e6;
border-style: solid;
-webkit-box-shadow: none;
-moz-box-shadow: none;
box-shadow: none;
}

body{
	font-family: 'Kentledge';
}

h4{
	font-size: 16px;
	letter-spacing: 2px;
}

h6{
	margin-bottom: 0px;
}

.table th, .table td{
	border-top: 0px;
}

select{
    text-indent: 5px;
}

.card{
background: #f6f6f6;
}

.dropdown-divider{
	border-top: rgba(0,0,0,0.06) 1px solid;
}

.navbar-nav .dropdown-menu{
position: absolute;
left:auto;
right:0;
max-height: 590px;
overflow-y: scroll;
-webkit-box-shadow: 10px 10px 15px -13px rgba(0,0,0,0.15);
-moz-box-shadow: 10px 10px 15px -13px rgba(0,0,0,0.15);
box-shadow: 10px 10px 15px -13px rgba(0,0,0,0.15);
}

.input-group input{
background: none;
border-radius: 7px;
color: #000;
}

.laadMeer{
	padding-top: 15px;
	padding-bottom: 15px;
	text-align: center;
	cursor: pointer;
	background-color: rgba(0,0,0,0.02);
}

.laadMeer span{
	font-size: 14px;
}

.laadMeer:hover{
	background-color: rgba(0,0,0,0.05);
}

.form-control{
padding-top: 7px;
}

.form-control, .form-control:focus{
background: none;
border: 1px solid #d9d9d9;
color: #000;
}

.fa, .fas{
color: rgba(0,0,0,0.7);
}



.page-link{
background: none;
color: #212529;
border-radius: 10;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.page-item .page-link{
	height: 40px;
}

.page-item.active .page-link{
background-color: rgba(0,0,0,0.07);
border: 1px solid #dee2e6;
color: #212529;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: 8px;
}

.page-item:last-child .page-link {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.page-wrapper > .page-content{
max-width: none;
padding-top: 0px;
}

.rdt input{
	height: 40px;
	width: 150px;
	border: 1px solid rgba(0,0,0,0.15);
	cursor: pointer;
	font-size: 15px;
	background: none !important;
	padding-right: 10px;
	padding-left: 20px;
	border-radius: 7px;
	padding-bottom: 5px !important;
}

.stats-td{
 width: auto !important;
}

.stats-td-omzet{
 width: 200px !important;
}

.input-group-text, .col-form-label{
	color: #212529 !important;
}

.period .rdt input{
	width: 100%;
}

.dateSettings input{
	width: 100%;
	border: none !important;
	font-size: 13.5px !important;
}

.settingsnav{
	cursor: pointer;
	width: 100%;
	height: 56px;
	padding-left: 30px;
	padding-top: 15px;
	padding-bottom: 2px;
	font-size: 16px;
	border-width: 0px;
	border-style: solid;
	border-color: #d12a5e;
}

.stats-tab-display{
	margin-top: 25px;
	display: flex;
}

.statstab{
	margin-right: 15px;
	font-size: 12px;
	padding: 8px 20px;
	border-radius: 15px;
	cursor: pointer;
}

.menucatnav{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-left: 30px;
	padding-right: 10px;
	padding-top: 16px;
	padding-bottom: 16px;
	font-size: 16px;
	border-width: 0px;
	border-style: solid;
	border-color: #d12a5e;
}

.menuoptienav{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 16px;
	padding-bottom: 16px;
	font-size: 14px;
	border-width: 0px;
	border-style: solid;
	border-color: #d12a5e;
}


.menucatnav i{
	margin-left: 0px !important;
	margin-right: 10px !important;
	display: none;
}

.menucatnav:hover i{
	display: inline;
}

.menucatnav:hover .groupNumIndicator{
	display: none;
}

.groupNumIndicator{
	height: 22px;
	padding: 0px 10px;
	border-radius: 5px;
	background-color: rgba(0,0,0,0.06);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10px;
	margin-right: 10px;
}

.groepInfoText{
	z-index: 1000;
    line-height: 18px;
    color: #55585a;
	font-family: 'Kentledge';
	display: none;
	position: absolute;
	left: 25px;
	top: 0;
	width: 300px;
	border-radius: 7px;
	background-color: #fff;
	padding: 20px;
	font-size: 14px;
	font-weight: 200;
	-webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.23);
	-moz-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.23);
	box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.23);
}

.groepInfoIcon:hover .groepInfoText{
	display: block;
}


.serviceInfoContainer{
	z-index: 1000;
	display: none;
	position: absolute;
	left: -5px;
	top: -155px;
	padding: 20px;
}

.serviceInfoText{
    line-height: 18px;
    color: #55585a;
	font-family: 'Kentledge';
	width: 430px;
	border-radius: 7px;
	background-color: #fff;
	padding: 20px;
	font-size: 14px;
	font-weight: 200;
	-webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.23);
	-moz-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.23);
	box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.23);
}

.serviceInfoIcon:hover .serviceInfoContainer{
	display: block;
}

.serviceInfoContainer:hover .serviceInfoContainer{
	display: block;
}

.btnpdfbtn{
	padding: 5px 20px;
	border-radius: 10px;
}

.btnpdfbtn:hover{
	background-color: rgba(0,0,0,0.05);
}

.btnpdfContainer{
	width: 170px;
	z-index: 1000;
	display: none;
	position: absolute;
	right: 0px;
	top: 35px;
	background-color: #fff;
	border-Radius: 5px;
	padding: 20px;
	font-size: 14px;
	font-weight: 200;
	-webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.23);
	-moz-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.23);
	box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.23);
}

.filebtnpdf:hover .btnpdfContainer{
	display: block;
}

.filebtnpdf{
	position: relative;
}


.is-moving{
	z-index: 9999;
}

.hightlightIcon2{
	font-size: 15px;
}

.hightlightIcon2mright{
	margin-right: 15px;
}

.nav-pills.custom-pills .nav-link{
	font-weight: 100;
	padding-bottom: 12px;
	padding-top: 10px;
	font-size: 15px;
}

.FullColorPlaceholder::placeholder {
	color: rgba(0,0,0,0.4) !important;
}

input::placeholder, ::placeholder {
	color: rgba(0,0,0,0.4) !important;
	opacity: 1;
}

input{
	padding-bottom: 0.475rem !important;
}

select {
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.minimal, select.minimal:focus {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em; background-repeat: no-repeat;
}

.dropdown-menu{
	border-radius: 10px;
	box-shadow: none;
}

.fmnames{
	letter-spacing: 1px !important;
}

.addBtnLst{
	cursor: pointer; 
	background: #eaeaea;
	text-align: center; 
	height: 38px;
	padding-top: 8px;
	margin-bottom: 20px;
	font-size: 14px; 
	font-weight: 500;
	border-radius: 7px; 
	border-width: 1px;
	border-style: solid;
	border-color: rgba(0,0,0,0.07);
}

.addBtnTop{
	cursor: pointer; 
	text-align: center; 
	height: 40px;
	padding-top: 8px;
	margin-bottom: 20px;
	font-size: 14px; 
	font-weight: 500;
	border: 1px solid rgb(217, 217, 217);
	border-radius: 7px; 
}

.modalItemSeperate{
	padding-bottom: 10px;
	border: 1px solid rgb(217, 217, 217);
	border-radius: 7px; 
}

.fa-ticket{
	transform: rotate(-45deg) scale(0.9);
}

.fa-store{
	transform: scale(0.95);
}

.fa-user-group{
	transform: scale(0.94);
}

.fa-chart-mixed{
	transform: scale(0.9);
}

.btn{
padding-top: 7px;
}

.addBtnLst:hover, .addBtnTop:hover{
	background: #3b3f42 !important;
	color: #fff;
}

.emptyContainer{
width: 100%; 
text-align: center;
min-height: 250px;
display: flex;
justify-content: center;
align-items: center;
border-top: 1px solid #dee2e6;
}


.input-group > .input-group-prepend > .input-group-text, .custom-file-input{
	background: none !important;
	border-right-width: 0px;
}
.sidebar-nav ul .sidebar-item .sidebar-link, .sidebar-nav ul .sidebar-item .sidebar{
	font-size: 0.95rem;
	padding: 12px;
}

#main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .sidebar-nav .hide-menu, #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .sidebar-nav .has-arrow:after{
	padding-left: 5px;
}

.modal-content{
border-radius: 7px;
padding: 7px;
}

.form-control, .input-group-text, .custom-file-label{
border-radius: 5px;
}

.custom-file-label::after{
border-radius: 0 5px 5px 0;
}

.modal-footer button{
border-radius: 5px;
}

.btn:hover{
-webkit-transform: translateY(-2px);
background-color: rgb(33, 37, 41) !important;
}

.hightlightBTN:hover{
background-color: rgb(33, 37, 41) !important;
}

.hightlightIcon:hover{
color: rgb(33, 37, 41) !important;
}

.hightlightIcon2:hover{
color: rgb(209, 42, 94) !important;
}

.nav-pills.custom-pills .nav-link.active{
border-bottom: 2px solid #d12a5e;
}

#main-wrapper[data-layout='vertical'][data-header-position='fixed'] .page-wrapper{
	padding-top: 10px !important;
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
  right: -50px;
  border-radius: 10px;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #d12a5e;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #d12a5e;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.loading-spinner{
  border: 6px solid rgba(0,0,0,0);
  border-radius: 50%;
  border-top: 6px solid #d12a5e;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.lockerDate{
	position: relative; 
	width: 100% !important;
}

.lockerDate input{
	padding-right: 40px;
	width: 100% !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.mainHead{
	padding-left: 30px;
	padding-right: 30px;
}

.rightCol{
	margin-left: 20px;
}

.dateselectwin{
	background: 'none';
	padding-top: 0px;
	padding-right: 0px; 
	padding-left: 0px;
	min-width: 240px;
}

.verkoopomzet{
	padding-top: 35px;
}

.emptytable{
	position: absolute;
	top: 235px;
	width: 100%; 
	display: flex; 
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.statsTable .table-responsive{
	border: none;
}

.menuRightActions{
	padding-left: 0px;
	padding-right: 0px;
	display: flex;
	justify-content: flex-end;
}

.menuTopContainer{
	margin-top: 42px;
	padding-left: 15px; 
	padding-right: 15px;
}

.menuTopCats{
	margin-top: 30px;
	padding-left: 15px;
	padding-right: 15px;
}

.menuDelButton{
	background-color: rgb(229, 57, 53); 
	border: none;
}

.graphTickets{
	margin-left: 20px;
	padding: 40px;
	padding-top: 30px;
}

.styledDropdown{
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
	border-radius: 5px;
    border: 1px solid #d9d9d9;
    color: #000;
	padding-top: 7px;
	height: calc(2.0625rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.styledDropdown:hover{
	color: #000;
}

.dropdownCheckWindow{
    margin-top: -7px;
    width: 235px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.dropdownCheckitem{
	display: flex;
	padding: 5px 15px;
}

.dropdownCheckbox{
	margin-right: 5px;
	margin-top: 3px;
}

.voorbeeld{
	padding: 10px 15px;
    background-color: rgba(0,0,0,.025);
    margin-top: 12px;
    word-break: break-all;
}

/* ---------------------------------------------------------------------------------*/
/* ------------------------------ mobile menu settings -----------------------------*/
/* ---------------------------------------------------------------------------------*/

@media (max-width: 767.98px) {

	.page-wrapper > .page-content{
		padding-top: 25px; 
		padding-left: 10px; 
		padding-right: 10px;
	}
	
	.mainHead{
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.left-sidebar{
		width: 220px !important;
	}
		
	#main-wrapper[data-sidebartype='mini-sidebar'] .left-sidebar{
		left: -220px;
	}
	
	#main-wrapper.show-sidebar .left-sidebar{
		left: 0px;
	}
	
	.mobileMenu{
		display: block !important
	}
	
	.nav-pills.custom-pills .nav-link{
		font-size: 12px
	}
	
	.nav-toggler{
		display: flex !important;
		justify-content: flex-end;
		cursor: pointer;
	}
	
	.rightCol{
		margin-left: 0px;
		padding: 0px;
	}
	
	.graph-display{
		position: relative;
		
	}
	
	.verkoopomzet .col-lg-3{
		margin-bottom: 20px;
	}
	
	.dateselectwin{
		margin-bottom: 45px;
	}
	
	.emptytable{
		width: 75%; 
	}
	
	.graph-menu{
		padding: 30px 25px !important;
		margin-left: 0px !important;
		margin-top: 35px;
	}
	
	.menuTopContainer{
		margin-top: 25px
	}
	
	.menuRightActions{
		margin-top: 20px;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	
	.menuRightActions .addBtnTop{
		width: auto !important;
		padding-left: 30px;
		padding-right: 30px;
		margin-bottom: 18px !important;
	}
	
	.menuTopCats{
		margin-top: 15px;
	}
	
	.modal-footer{
		flex-wrap: wrap-reverse;
	}
	
	.menuDelButton{
		margin-top: 15px;
	}
	
	.graphTickets{
		margin-top: 35px;
		margin-left: 0px;
		padding: 25px;
	}
	
	.graph-scroll{
		overflow-x: scroll;
	}
	
	.mobileMargin20{
		margin-top: 20px;
	}
	
	.mobileFullWidth{
		width: 100% !important;
	}
	
	.mobileMinWidth1000{
		min-width: 1000px !important;
	}
	
	.addBtnLstSettings{
		position: inherit !important;
		margin-top: 25px;
		margin-bottom: 25px;
	}
	
	.explainCol{
		padding: 15px 20px !important;
	}

	.side-stats{
		display: none;
	}

	
}